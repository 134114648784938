.footerContainer {
    background: white;
    padding-bottom: 30px;
    border-bottom: solid 5px #2D164A;

    @media (max-width: 768px) {
        padding-bottom: 90px;
    }
}

.footerText {
    text-align: center;
    padding: 40px 0;
    border-bottom: solid 2px #f1efef;
    margin-bottom: 28.5px;
}
.logoFooter {
    margin-bottom: 40px;
}

.footerInfo {
    margin-bottom: 0;
    padding-bottom: 0;
    
    @media (max-width: 768px) {
        line-height: 1rem;
    }
}

.address {
    display: flex;
    align-items: center;
    i {
        margin-right: .5rem;
    }
}

.left {
    text-align: left;

    p, div{
        display: flex;
        margin: 0rem 0rem .2rem 0rem;
        align-items: center;
    }

    a {
        display: inline-block;
        padding-right: .5rem;
    }

}

.center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    p, div{
        display: flex;
        margin: 0rem 0rem .2rem 0rem;
        align-items: center;
    }

    a {
        display: inline-block;
        padding-right: .5rem;
    }
}

.right {
    text-align: right;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p, div{
        display: flex;
        margin: 0rem 0rem .2rem 0rem;
        align-items: center;
    }

    a {
        display: inline-block;
        padding-right: .5rem;
    }
}


@media (max-width: 900px) {
    .left, .center, .right {
        text-align: center !important;
        width: 100%;
        display: flex;
        margin: 1rem;
        align-items: center;
        flex-direction: column;

        img {
            margin: 0 auto;
        }
    }

    .logo {
        text-align: center;
        align-items: center;
        justify-content: center;
        img {
            display: block !important;
            margin: 0 auto !important;

        }
    }
 }
