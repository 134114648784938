.modal.modal {
    max-width: 85.375rem;
    width: 100%;
    max-width: 450px !important;
    margin: 0 1rem;
    min-height: 32.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1199px) {
        min-height: 24.5rem;
    }

    p {
        margin: 0;
    }

    .modalContent {
        all: revert;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 50.5rem;
        width: 100%;
    }

    .modalTitle {
        font-weight: 700;
        font-size: 2rem;
        margin: 2.125rem 0 0 0;
        text-align: center;
        @media (max-width: 1199px) {
            font-size: 1.75rem;
            margin: 1rem 0 0 0;
        }
    }

    .modalTextWrapper {
        all: revert;
        min-height: 6.8125rem;
        background-color: #d4e2fe;
        border-radius: 0.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #0c0f14;
        margin: 2.3125rem 0 2.25rem 0;
        @media (max-width: 1199px) {
            margin: 1.5rem 0 1.5rem 0;
            border-radius: 0.5rem;
            min-height: 5.25rem;
            padding: 0.75rem 0.25rem;
        }
    }

    .modalText {
        text-align: center;
        font-weight: normal;
        font-size: 1.25rem;
        max-width: 35.375rem;
        font-weight: 500;
        @media (max-width: 1199px) {
            font-size: 1rem;
        }
    }

    .buttonTryagain {
        min-height: 4.375rem;
        max-width: 17.1875rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.375rem;
        padding: 0 0.625rem;
        @media (max-width: 1199px) {
            min-height: 3.125rem;
            font-size: 1rem;
        }
        @media (max-width: 575px) {
            width: 100%;
            max-width: revert;
        }
    }
}
