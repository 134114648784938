.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    transition-duration: .5s;
    opacity: 1;
    z-index: 90;
}

.modalContent {
    background: white;
    width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    min-height: 100px;
    border-radius: 20px;
    padding: 15px 20%;
    transition-duration: .4s;
    position: relative;
    transform-style: preserve-3d;

    @media (max-width: 768px) {
        max-width: calc(100% - 32px);
    }
}

.closeButton {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 16px;
    color: #6D7688;
    transition-duration: .3s;

    &:hover {
        color: #ff0000;
        scale: 1.1;
    }
}