.userBlockedContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;

    [class~=simple-content-title] {
        color: #474E5E !important;  
        font-weight: 700 !important;
        font-size: 24px !important;    
    }

    [class~=simple-content-subtitle] {
        color: #474E5E !important;  
        font-weight: 500 !important;
        font-size: 16px !important;    
        max-width: 300px !important;
    }

    [class~=simple-content-primary-button] {
        background-color: #2D164A !important;
    }
}