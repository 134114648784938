.imageFluid {
    max-width: 100%;
    height: 100%;
}

.loginContainer {
    width: 976px;
    margin: 0 auto;

    padding: 64px 0;
    max-height: 968px;

    >div,
    >div>div,
    >div>div>div {
        padding: 0 !important;
    }

    @media (max-width: 767px) {
        width: 90%;
    }

    p {
        margin: 0;
    }

    .contentRight {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border: none;

        .backButton {
            cursor: pointer;
            font-size: 14px;
            text-decoration: underline;
            color: #0052cc;
            font-weight: 600;
            text-align: center;
            margin: 0 auto 0 auto;
            display: block;
            margin: 20px 0 0 0;
        }

        .questions {
            font-size: 11px;
            text-decoration: underline;
            color: #0052cc;
            font-weight: 600;
            text-align: center;
            margin: 0 auto 0 auto;
            display: block;
            margin: 20px 0 0 0;
            cursor: pointer;
        }

        .noAccount {
            font-size: 12px;
            color: #262c36;
            font-weight: 400;
            text-align: center;
        }

        .noAccountLink {
            color: #6a53b2;
            font-weight: 700;
            margin: 0 0 0 5px;
        }

        .feedBackIconWrapper {
            display: grid;
            place-items: center;
            margin: 24px 0 8px 0;
        }

        .modalText {
            text-align: center;
            font-weight: normal;
            font-size: 0.75rem !important;
            max-width: 378px;
            font-weight: 500;

            @media (max-width: 1199px) {
                font-size: 1rem;
            }

            span {
                font-weight: 700;
            }
        }

        &.feedBackAnalysis,
        &.feedBackError {
            .buttonAccept {
                height: 50px;
            }
        }

        &.feedBackError {
            .buttonsWrapper {
                margin: 37px 0 16px 0;
            }
        }
    }

    .title {
        color: #262c36;
        font-size: 32px;
        text-align: center;
        font-weight: 500;
    }

    .subtitle {
        color: #6d7688;
        margin: 16px 0;
        text-align: center;
        font-size: 16px;

        @media (max-width: 1199px) {
            margin: 1.5rem 0 1rem 0;
            color: #6d7688;
        }
    }

    .text {
        margin: 0 0 1.9375rem 0;
        font-size: 1.375rem;
        color: #262c36;
        font-weight: 400;

        @media (max-width: 1199px) {
            font-size: 1.125rem;
        }

        span:first-of-type {
            font-weight: 600;
        }

        span {
            font-weight: 700;
        }
    }

    .cpfInputWrapper {
        max-width: 30.1875rem;
    }

    .selectAccountInputWrapper {
        max-width: 30.1875rem;
        margin: 30px 20px;
    }

    .buttonsWrapper {
        margin: 16px 0 16px 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        @media (max-width: 1199px) {
            margin: 0.5rem 0 0.5rem 0;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    .buttonAccept {
        background-color: #2D164A !important;
        font-size: 1rem;
        height: 48px;

        @media (max-width: 1199px) {
            width: 100%;
            display: grid;
            place-items: center;
            max-width: 311px;
        }

        @media (max-width: 575px) {
            width: 100%;
            max-width: 100%;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: #1a0f33 !important;
        }
    }

    .buttonAccept.buttonDisabled {
        background-color: #c8d2e1 !important;
        pointer-events: none;
    }

    .buttonDecline {
        font-size: 1rem;
        color: #6a53b2;
    }

    .contentDiv {
        height: 100%;
        width: 100%;
        padding: 40px !important;
    }

    .loadingDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;

        p {
            padding-bottom: 90px;
        }
    }

    .loading {
        display: flex;
        justify-content: center;
        margin: 46px 0 46px 0;
    }

    .loadingText {
        text-align: center;
        font-size: 1.375rem;
        color: #262c36;
        font-weight: 400;
        padding: 0 0 90px 0;

        @media (max-width: 1199px) {
            font-size: 1.125rem;
            padding: 0 0 20px 0;
        }
    }

    .pinFeedbackTitle {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #474e5e;
    }

    .pinFeedbackSubtitle.pinFeedbackSubtitle {
        font-size: 14px;
        font-weight: 500;
        color: #6d7688;
        text-align: center;
        margin: 10px 0 10px 0;

        span {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .userNotFoundText {
        font-size: 12px;
        font-weight: 500;
        text-align: left;
        color: #db2828;
        margin-top: -10px;
        margin-bottom: 10px;
    }

    .invalidPasswordText {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #db2828;
        margin-bottom: 5px;

        span {
            font-weight: 700;
        }
    }

    .pinFeedbackNoCode.pinFeedbackNoCode {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #6d7688;
        margin: 15px 0 0 0;
    }

    .blockedWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 0 32px 0;
    }

    .modalTextWrapper {
        margin: 23px 0 32px 0;
        display: flex;
        justify-content: center;
    }

    .attemptsError {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .feedBackIconWrapper {
        display: grid;
        place-items: center;
        margin: 0 0 28px 0;
    }

    .pinInput {
        margin: 16px 0 16px 0;

        input {
            width: 47px;
            height: 73px;
            font-size: 32px;

            @media (max-width: 370px) {
                width: 35px;
                height: 60px;
                font-size: 24px;
            }
        }
    }

    .floatingLogo {
        position: absolute;
        top: 15px;
        left: 15px;
    }
}

.redHighlight.redHighlight {
    outline: solid 2px red;
}

.title {
    margin-bottom: 16px;

    @media (min-width: 1200px) {
        margin-bottom: 32px;
    }
}

.forgotPassword {
    cursor: pointer;
    margin-bottom: 36px;
    text-align: right;
    width: 100%;
}

.content {
    display: flex;
    width: 100%;
}

.contentLeft,
.contentRight {
    width: 50%;
}

@media (max-width: 1199px) {
    .contentLeft {
        display: none;
    }

    .contentRight {
        width: 100%;
    }

    .cpfInputWrapper {
        max-width: 100% !important;
    }

    .selectAccountInputWrapper {
        max-width: 100% !important;
    }
}

.contentRight .bordered,
.contentRight.bordered {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    @media (max-width: 1199px) {
        border-top-right-radius: 0.75rem !important;
        border-bottom-right-radius: 0.75rem !important;
        border-top-left-radius: 0.75rem !important;
        border-bottom-left-radius: 0.75rem !important;
    }
}

.contentLeft .bordered,
.contentLeft.bordered {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;

    @media (max-width: 1199px) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.centerContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    >* {
        text-align: center;
        margin-bottom: 30px;
    }

    max-width: 960px;
}

.registerModal {
    min-width: 350px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 800px) {
        width: calc(100% - 52px);
        justify-content: initial;
    }

    user-select: none;
    padding: 36px 50px !important;

    @media(max-width: 768px) {
        padding: 50px 24px 20px 24px !important;
    }

    .title {
        font-weight: 700 !important;
        margin-bottom: 15px !important;
    }

    .subtitle {
        font-weight: 400 !important;
        margin-bottom: 15px !important;
    }
}

.buttonsWrapper {
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 0px !important;
    width: 100%;

    flex-direction: column;

    @media (max-width: 1199px) {
        margin: 0.5rem 0 0.5rem 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.buttonRegister {
    background-color: #2D164A !important;
    font-size: 1rem;
    height: 48px;
    width: 100%;
    justify-items: center;

    @media (max-width: 1199px) {
        width: 100%;
        display: grid;
        place-items: center;
        max-width: 311px;
    }

    @media (max-width: 575px) {
        width: 100%;
        max-width: 100%;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: #1a0f33 !important;
    }
}

.buttonRegister.buttonDisabled {
    background-color: #c8d2e1 !important;
    pointer-events: none;
}

.closeModalButton {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none !important;
    background-color: transparent;
    padding: 10px !important;
    cursor: pointer;

    p {
        margin: 0 8px 0 0;
    }
}

.inputWrapper {
    flex-direction: column !important;
    gap: 10px;
    width: 100%;
    text-align: left;
    margin-bottom: 15px !important;
}

.alert {
    width: 100%;
    margin-right: 120px;
    justify-content: center;
    align-items: center;
    padding: 6px 12px !important;

    @media (max-width: 992px) {
        margin-right: 30px;
    }

    @media (max-width: 768px) {
        margin-right: 0px;
    }
}

.alertText {
    margin: 0 !important;
    font-weight: 400 !important;
}

.alertWrapper {
    margin-bottom: 10px !important;
}

.bottomLinkText {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400 !important;
    color: #0052CC !important;
    text-decoration: underline !important;
    font-size: 12px !important;
    align-self: center !important;
    height: 20px !important;
}