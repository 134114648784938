.whatsappCtaFloatingButton {
    padding: 0;
    height: 3.4375rem;
    width: 3.4375rem;
    &:hover {
        background: none;
    }
    background: none;
    position: fixed;
    top: 87.5%;
    right: 0.1%;
    z-index: 9999;
    margin: 1rem;
    width: 100%;
    max-width: max-content;
    height: 3.4375rem;

    @media (max-width: 768px) {
        top: 77%;
    }
}