.modalWrapper {
    padding: 32px;
    width: auto !important;
    height: auto !important;
    max-width: 500px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 16px !important;
}

.iconClose {
    align-self: flex-end !important;
    display: flex;
    width: 24px !important;
    height: 24px !important;
}

.iconCloseWrapper {
    position: relative;
    display: flex;
    justify-content: right;
    width: 100%;
}

.buttonCloseModal {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.mainIconWrapper {
    width: 78px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-bottom: 16px;
}

.title {
    font-size: 2rem !important;
    font-weight: 500 !important;
    text-align: center !important;
    margin: 0 0 16px 0 !important
}

.subtitle {
    font-size: 1.5rem !important;
    margin: 0 0 32px 0 !important;
    font-weight: 500 !important;
    color: #6D7688 !important;
    text-align: center !important;

    @media screen and (max-width: 600px) {
        margin: 16px 0 !important;
    }
}

.buttonsDirectionColumn {
    flex-direction: column-reverse !important;
}

.buttonsDirectionRow {
    flex-direction: row !important;
}

.buttonsContainer {
    margin-top: 20px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @media screen and (max-width: 600px) {
        flex-direction: column-reverse !important;
    }
}

.leftButton:hover {
    button {
        background-color: #6A53B2 !important;
        color: #FFF !important;
    }
}

.buttonRow button:hover {
    background-color: #5336ab;
}

.primaryButton {
    background-color: #6A53B2 !important;
    color: #FFF !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.secondaryButton {
    background-color: #FFF !important;
    color: #6A53B2 !important;
    border: 2px solid #6A53B2 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

@media screen and (max-width: 600px) {
    .modalWrapper {
        padding: 16px;
    }

    .buttonRow {
        width: 100% !important;
        flex-direction: column-reverse !important;
        gap: 8px !important;

        button {
            width: 100% !important;
        }
    }

    .title {
        font-size: 1.5rem !important;
    }

    .subtitle {
        font-size: 1.125rem !important;
    }
}

.loadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 20px 0;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}