.background {
    background-color: #ECF0F4;
}

.header {
    background-color: #a9b4bf;
    max-height: 50px;
    width: 100vw;
    padding: 15px 0;

    @media screen and (min-width: 768px) {
        display: none !important;
    }
}

.headerImg {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
