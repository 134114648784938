.unlockUserContent {
    padding: 32px;

    [class~=simple-content-title] {
        color: #474E5E !important;  
        font-weight: 700 !important;
        font-size: 24px !important;    
    }

    [class~=simple-content-subtitle] {
        color: #474E5E !important;  
        font-weight: 500 !important;
        font-size: 16px !important;    
        max-width: 300px !important;
    }

    [class~=simple-content-primary-button] {
        background-color: #2D164A !important;
    }
}

.successResendEmailChildren {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.successResendEmailChildrenTitle {
    font-size: 24px !important;
    margin: 0 !important;
    font-weight: 700 !important;
    text-align: center !important;
    margin: 0 0 16px 0 !important;
    color: #474E5E !important;
    word-break: break-word !important;
}

.buttonContactSupport {
    color: #6A53B2 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-align: center !important;
    text-decoration: underline !important;
}